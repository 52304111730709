<template>
  <div>
    <div>
      <ave-tabset :navs="list" :active="tabsetActive" @change="changethis">
        <div slot-scope="{tab}">
          {{ tab.name }}
        </div>
      </ave-tabset>
    </div>
    <div style="padding:8px;clear:both;">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackstageMain',
  data() {
    return {
      list: [
        { name: '任务管理', path: '/backstage/bklist', active: true },
        { name: '质量确认管理', path: '/backstage/qctrace', active: false },
        { name: '统计管理', path: '/backstage/statistic', active: false }
      ],
      tabsetActive: {}
    };
  },
  mounted() {
    this.tabsetActive = this.list[0];
    this.$router.push({ path: this.tabsetActive.path });
  },
  methods: {
    changethis($event) {
      this.tabsetActive = $event;
      this.$router.push({ path: $event.path });
    }
  }
};
</script>

<style scoped>

</style>
